var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outBox"},[_vm._m(0),_c('div',{staticClass:"call"},[_c('Mytitle',{attrs:{"data":{
        h: '联系我们',
      }}}),_vm._m(1)],1),_c('div',{staticClass:"follow"},[_c('Mytitle',{attrs:{"data":{
        h: '关注我们',
        p: '您可以通过扫描下方的二维码关注我们的微信公众号-拓幻科技，官方微博-合肥拓幻科技',
      }}}),_vm._m(2)],1),_c('div',{attrs:{"id":"container"}},[_c('el-amap',{attrs:{"vid":"amapDemo","zoom":_vm.zoom,"center":_vm.center,"dragEnable":false,"zoomEnable":false}},[_c('el-amap-marker',{attrs:{"position":_vm.position,"label":{
          content: '南京市雨花台区宁双路云密城K栋F7',
          offset: [-88, -23],
        }}})],1)],1),_c('Try',{attrs:{"id":"call"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"top_text"},[_c('h1',[_vm._v("连接用户，提升体验")]),_c('p',[_vm._v("我们随时欢迎您的咨询和访问，为您提供真诚有效的服务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"call_conter"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("商务咨询")]),_c('span',{staticClass:"one"},[_c('img',{attrs:{"src":require("@/assets/call/icon_lianxi_tel_blue@2x.png")}}),_vm._v(" 17136231898(同微信号) ")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/call/icon_lianxi_qq_blue@2x.png")}}),_vm._v("3599350770")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/call/icon_lianxi_mail_blue@2x.png")}}),_vm._v("sales@tillusory.cn")]),_c('h3',[_vm._v("渠道合作")]),_c('span',{staticClass:"one"},[_c('img',{attrs:{"src":require("@/assets/call/icon_lianxi_tel_blue@2x.png"),"alt":""}}),_vm._v(" 13101897258(同微信号)")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/call/icon_lianxi_qq_blue@2x.png"),"alt":""}}),_vm._v("1838852977")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/call/icon_lianxi_mail_blue@2x.png"),"alt":""}}),_vm._v("market@tillusory.cn")])]),_c('div',{staticClass:"right"},[_c('h3',[_vm._v("让我们与您联系")]),_c('p',[_vm._v("留下您的联系方式，我们的专属顾问会在1个工 作日内和您联系")]),_c('a',{attrs:{"href":"#call"}},[_vm._v("现在联系")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fol_conter"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/call/contact_weixin@2x.png"),"alt":""}}),_c('span',[_vm._v("拓幻科技")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/call/contact_weibo@2x.png"),"alt":""}}),_c('span',[_vm._v("合肥拓幻科技")])])])
}]

export { render, staticRenderFns }