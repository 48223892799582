<template>
  <div class="outBox">
    <!-- 大图部分 -->
    <div class="top">
      <div class="top_text">
        <h1>连接用户，提升体验</h1>
        <p>我们随时欢迎您的咨询和访问，为您提供真诚有效的服务</p>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="call">
      <Mytitle
        :data="{
          h: '联系我们',
        }"
      ></Mytitle>

      <div class="call_conter">
        <div class="left">
          <h3>商务咨询</h3>
          <span class="one">
            <img src="@/assets/call/icon_lianxi_tel_blue@2x.png" />
            17136231898(同微信号)
          </span>
          <span
            ><img
              src="@/assets/call/icon_lianxi_qq_blue@2x.png"
            />3599350770</span
          >
          <span
            ><img
              src="@/assets/call/icon_lianxi_mail_blue@2x.png"
            />sales@tillusory.cn</span
          >
          <h3>渠道合作</h3>
          <span class="one">
            <img src="@/assets/call/icon_lianxi_tel_blue@2x.png" alt="" />
            13101897258(同微信号)</span
          >
          <span
            ><img
              src="@/assets/call/icon_lianxi_qq_blue@2x.png"
              alt=""
            />1838852977</span
          >
          <span
            ><img
              src="@/assets/call/icon_lianxi_mail_blue@2x.png"
              alt=""
            />market@tillusory.cn</span
          >
        </div>
        <div class="right">
          <h3>让我们与您联系</h3>
          <p>留下您的联系方式，我们的专属顾问会在1个工 作日内和您联系</p>
          <a href="#call">现在联系</a>
        </div>
      </div>
    </div>
    <!-- 关注我们 -->
    <div class="follow">
      <Mytitle
        :data="{
          h: '关注我们',
          p: '您可以通过扫描下方的二维码关注我们的微信公众号-拓幻科技，官方微博-合肥拓幻科技',
        }"
      ></Mytitle>

      <div class="fol_conter">
        <div>
          <img src="@/assets/call/contact_weixin@2x.png" alt="" />
          <span>拓幻科技</span>
        </div>
        <div>
          <img src="@/assets/call/contact_weibo@2x.png" alt="" />
          <span>合肥拓幻科技</span>
        </div>
      </div>
    </div>
    <!-- 地图 -->
    <div id="container">
      <el-amap
        vid="amapDemo"
        :zoom="zoom"
        :center="center"
        :dragEnable="false"
        :zoomEnable="false"
      >
        <el-amap-marker
          :position="position"
          :label="{
            content: '南京市雨花台区宁双路云密城K栋F7',
            offset: [-88, -23],
          }"
        ></el-amap-marker>
      </el-amap>
    </div>
    <!-- 试用 -->
    <Try id="call"></Try>
  </div>
</template>
<script>
import Try from "@/components/Try/index";
import Mytitle from "@/components/Mytitle/index"; //标题

export default {
  components: {
    Try,
    Mytitle,
  },
  data() {
    return {
      zoom: 15,
      center: [118.751288, 31.970053],
      position: [118.751288, 31.970053],
    };
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
.outBox {
  width: 100%;
  //背景大图部分
  .top {
    width: 100%;
    height: 500px;
    line-height: 400px;
    text-align: center;
    color: #fff;
    background: url("~@/assets/call/联系我们.jpg") no-repeat;
    background-size: 100% 500px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    .top_text {
      height: 197px;
      h1 {
        height: 76px;
        font-size: 44px;
        font-weight: 500;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 47px;
      }
    }
  }
  //联系我们
  .call {
    height: 400px;
    .call_conter {
      width: 1140px;
      height: 144px;
      margin: auto;
      background: #fff;
      box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
      padding: 39px 50px;
      font-weight: 400;
      display: flex;
      .left {
        width: 653px;
        border-right: 1px solid #eee;
        margin-right: 71px;
        h3 {
          height: 40px;
          font-size: 16px;
          color: #212121;
        }
        img {
          width: 14px;
          margin-right: 20px;
          vertical-align: middle;
        }
        span {
          width: 176px;
          display: inline-block;
          height: 40px;
          font-size: 14px;
          color: #666666;
        }
        span.one {
          width: 240px;
        }
      }
      .right {
        width: 351px;
        h3 {
          height: 40px;
          font-size: 16px;
          color: #212121;
        }
        p {
          width: 301px;
          height: 70px;
          font-size: 14px;
          color: #666666;
        }
        a {
          display: block;
          text-align: center;
          line-height: 40px;
          width: 160px;
          height: 40px;
          border: 1px solid #4c8cf5;
          border-radius: 4px;
          font-size: 16px;
          color: #4c8cf5;
        }
        a:hover {
          background: #4c8cf5;
          color: #ffffff;
        }
      }
    }
  }
  //关注我们
  .follow {
    height: 463px;
    .fol_conter {
      width: $width;
      margin: auto;
      text-align: center;
      display: flex;
      justify-content: center;
      div:first-child {
        margin-right: 360px;
      }
      span {
        height: 80px;
        line-height: 50px;
        font-size: 18px;
        font-weight: 400;
        color: #222222;
        display: block;
      }
      img {
        width: 208px;
        height: 208px;
      }
    }
  }
  // 地图
  #container {
    height: 500px;
    .amap-box .amap-marker-label {
      position: absolute;
      z-index: 2;
      border: 1px solid #ccc;
      background-color: white;
      white-space: nowrap;
      cursor: default;
      padding: 3px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>